import { FC, useMemo } from 'react'
import { useDomain } from 'context'
import styled from 'styled-components'
import { ArrowRight } from 'svgIcons'
import { BestStoryType } from 'app.constants/enums'
import CbtBestPost from './cbtBestStory/CbtBestPost'
import CommunityBestPost from './communityBestStory/CommunityBestPost'
import useCurrentMenu from './hooks/useCurrentMenu'

const BestStoryAll: FC = () => {
  const { protocol, xen, academyCommunityBaseUrl } = useDomain()
  const { currentMenu, changeCurrentMenu } = useCurrentMenu()

  const expandLabel = useMemo(() => {
    if (currentMenu === BestStoryType.COMMUNITY) return '커뮤니티 더보기'
    if (currentMenu === BestStoryType.CBT_COMMUNITY) return '시험정보방 더보기'

    return '더보기'
  }, [currentMenu])

  return (
    <StyledWrapper>
      <div className="menu-box">
        <button
          className="menu-title"
          data-active={currentMenu === BestStoryType.COMMUNITY}
          onClick={() => changeCurrentMenu(BestStoryType.COMMUNITY)}
        >
          커뮤니티
        </button>
        <button
          className="menu-title"
          data-active={currentMenu === BestStoryType.CBT_COMMUNITY}
          onClick={() => changeCurrentMenu(BestStoryType.CBT_COMMUNITY)}
        >
          시험정보방
        </button>
      </div>
      <div className="list-box">
        {currentMenu === BestStoryType.COMMUNITY && (
          <>
            <CommunityBestPost />
            <a href={`${protocol}://${xen}/best`} className="expand-btn">
              {expandLabel}
              <ArrowRight />
            </a>
          </>
        )}
        {currentMenu === BestStoryType.CBT_COMMUNITY && (
          <>
            <CbtBestPost />
            <a
              href={`${academyCommunityBaseUrl}/cbt-best`}
              className="expand-btn"
            >
              {expandLabel}
              <ArrowRight />
            </a>
          </>
        )}
      </div>
    </StyledWrapper>
  )
}

export default BestStoryAll

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  .menu-box {
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    .menu-title {
      width: 100%;
      height: 40px;
      border: none;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
        border-bottom: 2px solid #01a0ff;
      }
      :hover {
        color: #01a0ff;
      }
    }
  }
  .list-box {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .expand-btn {
      cursor: pointer;
      font-size: 12px;
      border: none;
      background: none;
      width: fit-content;
      margin: 20px;
      padding: 0px;
      svg {
        width: 10px;
        height: 10px;
        transform: translateY(0.5px);
      }
    }
  }
`
