import { useEffect, useState } from 'react'
import { BestStoryType } from 'app.constants/enums'

const useCurrentMenu = () => {
  const [currentMenu, setCurrentMenu] = useState<BestStoryType | null>(null)

  const changeCurrentMenu = (menu: BestStoryType) => {
    return setCurrentMenu(menu)
  }

  useEffect(() => {
    setCurrentMenu(
      Math.random() < 0.5
        ? BestStoryType.CBT_COMMUNITY
        : BestStoryType.COMMUNITY,
    )
  }, [])

  return { currentMenu, changeCurrentMenu }
}

export default useCurrentMenu
