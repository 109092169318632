import { ApolloError } from '@apollo/client'
import {
  UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment,
  useGqlActivityListFromAdsByPlacementCodeQuery,
} from 'generated/graphql'
import { ActivityTypeID } from 'constants/enums'

type TUseGetActivityListFromAdsByPlacementCodeFn = (params: {
  adPlacementCode: string
  restVariables?: {
    isActivityCard?: boolean
    isActivityListItem?: boolean
  }
  activityTypeID?: ActivityTypeID
}) => {
  ads?: Array<UseGetActivityListFromAdsByPlacementCode_Activity_ActivityWithAdFragment>
  loading: boolean
  error?: ApolloError
}

const useGetActivityListFromAdsByPlacementCode: TUseGetActivityListFromAdsByPlacementCodeFn =
  ({ adPlacementCode, restVariables, activityTypeID }) => {
    const noticeBannerQueryVariables = {
      isEducation: activityTypeID === ActivityTypeID.EDUCATION,
      isRecruit:
        activityTypeID === ActivityTypeID.RECRUIT ||
        activityTypeID === ActivityTypeID.INTERN,
      isActivity:
        activityTypeID === ActivityTypeID.ACTIVITY ||
        activityTypeID === ActivityTypeID.CONTEST,
    }

    const { data, loading, error } =
      useGqlActivityListFromAdsByPlacementCodeQuery({
        variables: {
          adPlacementCode,
          ...(restVariables && restVariables),
          ...noticeBannerQueryVariables,
        },
      })

    return {
      ads: data?.activityListFromAdsByPlacementCode,
      loading,
      error,
    }
  }

export default useGetActivityListFromAdsByPlacementCode
