export enum BestStoryType {
  CHANNEL = 'channel',
  COMMUNITY = 'community',
  CBT_COMMUNITY = 'cbtCommunity',
  ALL = 'all',
}

export type TBestStoryType =
  | BestStoryType.CHANNEL
  | BestStoryType.COMMUNITY
  | BestStoryType.CBT_COMMUNITY
  | BestStoryType.ALL

export enum ChannelUserType {
  NORMAL = 'normal',
  CHANNEL = 'channel',
  OWNER = 'owner',
}
