import { FC, useMemo } from 'react'
import { useDomain } from 'context'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { ArrowRight } from 'svgIcons'
import { BestStoryType, TBestStoryType } from 'app.constants/enums'

type TProps = {
  type: TBestStoryType
  isExpandBtn: boolean
}
const BestStoryHeader: FC<TProps> = ({ type, isExpandBtn }) => {
  const router = useRouter()
  const { protocol, xen, academyCommunityBaseUrl } = useDomain()

  const handleClickExpand = () => {
    if (type === BestStoryType.COMMUNITY) {
      window.location.href = `${protocol}://${xen}/best`
    } else if (type === BestStoryType.CBT_COMMUNITY) {
      window.location.href = `${academyCommunityBaseUrl}/cbt-best`
    } else if (type === BestStoryType.CHANNEL) {
      router.push({
        pathname: '/channel',
        query: {
          tab: 'home',
          'order-by': 'popular',
        },
      })
    }
  }

  const BestTitle = useMemo(() => {
    if (type === BestStoryType.COMMUNITY) return '커뮤니티 BEST 소식'
    if (type === BestStoryType.CBT_COMMUNITY) return '시험정보방 BEST 소식'
    if (type === BestStoryType.CHANNEL) return '기업채널 BEST 소식'
    return '링커리어 BEST 이야기'
  }, [type])

  return (
    <StyledWrapper>
      <div className="title">{BestTitle}</div>
      {isExpandBtn && (
        <button className="expand-more" onClick={handleClickExpand}>
          더보기
          <ArrowRight />
        </button>
      )}
    </StyledWrapper>
  )
}

export default BestStoryHeader

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px 20px 0;
  .title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
  .expand-more {
    border: none;
    background: none;
    padding: 0px;
    font-size: 12px;
    font-weight: 500;
    svg {
      width: 10px;
      height: 10px;
      transform: translateY(0.5px);
    }
  }
`
