import { useEffect, useRef } from 'react'
import { AD_IMPRESSION_THRESHOLD } from '../constants/constants'

interface UseAdImpressionTrackingParams {
  /** impression 이벤트가 발생했을 때 실행될 콜백 함수 */
  onVisible: () => void
  /** useEffect의 의존성 배열 */
  dependencies?: any[]
}

/**
 * 단일 광고 요소가 90% 이상 viewport에 노출되었을 때 impression을 추적하는 커스텀 훅
 *
 * @description
 * 광고 영역이 90% 이상 노출될 때마다 onVisible 콜백 함수를 호출합니다.
 *
 * @param {Object} params - 훅 파라미터
 * @param {Function} params.onVisible - 광고가 viewport에 노출되었을 때 호출되는 콜백 함수
 * @param {Array} [params.dependencies] - useEffect의 의존성 배열. 이 값들이 변경될 때마다 impression을 다시 추적합니다.
 *
 * @returns {Object} 훅 반환값
 * @returns {MutableRefObject<HTMLDivElement | null>} containerRef - 광고 컨테이너 요소를 참조하는 ref 객체
 */
const useAdImpressionTracking = <T extends HTMLElement>({
  onVisible,
  dependencies = [],
}: UseAdImpressionTrackingParams) => {
  const containerRef = useRef<T | null>(null)

  useEffect(() => {
    if (!containerRef.current) return

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isInViewport =
            entry.isIntersecting &&
            entry.intersectionRatio >= AD_IMPRESSION_THRESHOLD

          if (isInViewport) {
            onVisible()
          }
        })
      },
      {
        threshold: [0.9],
        root: null,
        rootMargin: '0px',
      },
    )

    observer.observe(containerRef.current)
    return () => observer.disconnect()
  }, [...dependencies]) // 외부에서 전달받은 의존성 배열 사용

  return { containerRef }
}

export default useAdImpressionTracking
