import { useMemo, useState } from 'react'
import { BestPostListType } from 'generated/graphql.community'

const usePostBestTabs = () => {
  const [currentTab, setCurrentTab] = useState(BestPostListType.RECENT)

  const PostBestTabs = useMemo(() => {
    return [
      {
        label: '실시간',
        active: currentTab === BestPostListType.RECENT,
        onClick: () => setCurrentTab(BestPostListType.RECENT),
      },
      {
        label: '주간',
        active: currentTab === BestPostListType.LAST_WEEK,
        onClick: () => setCurrentTab(BestPostListType.LAST_WEEK),
      },
      {
        label: '월간',
        active: currentTab === BestPostListType.LAST_MONTH,
        onClick: () => setCurrentTab(BestPostListType.LAST_MONTH),
      },
      {
        label: '댓글순',
        active: currentTab === BestPostListType.REPLY_COUNT,
        onClick: () => setCurrentTab(BestPostListType.REPLY_COUNT),
      },
      {
        label: '추천순',
        active: currentTab === BestPostListType.VOTE_COUNT,
        onClick: () => setCurrentTab(BestPostListType.VOTE_COUNT),
      },
    ]
  }, [currentTab])

  return { currentTab, PostBestTabs }
}

export default usePostBestTabs
