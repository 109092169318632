import { FC } from 'react'
import styled, { css } from 'styled-components'
import { BestStoryType, TBestStoryType } from 'app.constants/enums'
import BestStoryAll from './BestStoryAll/BestStoryAll'
import CbtBestPost from './BestStoryAll/cbtBestStory/CbtBestPost'
import CommunityBestPost from './BestStoryAll/communityBestStory/CommunityBestPost'
import BestStoryHeader from './BestStoryHeader'

interface IBestStoryProps {
  type: TBestStoryType
  isExpandBtn?: boolean
  isBorder?: boolean
  isRadius?: boolean
  size?: 'small' | 'large'
  className?: string
}

const BestStory: FC<IBestStoryProps> = ({
  type,
  isExpandBtn = true,
  isBorder = true,
  isRadius = false,
  size = 'large',
  className,
}) => {
  return (
    <StyledWrapper
      className={className}
      data-border={isBorder}
      data-radius={isRadius}
      data-small={size === 'small'}
      data-all={type === BestStoryType.ALL}
    >
      <BestStoryHeader type={type} isExpandBtn={isExpandBtn} />
      {type === BestStoryType.COMMUNITY && <CommunityBestPost />}
      {type === BestStoryType.CBT_COMMUNITY && <CbtBestPost />}
      {type === BestStoryType.ALL && <BestStoryAll />}
    </StyledWrapper>
  )
}

export default BestStory

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 411px;
    background-color: ${theme.background.bg_8};
    &[data-all='true'] {
      min-height: 500px;
    }
    &[data-border='true'] {
      border: 1px solid ${theme.line.line_3};
      ${theme.isMobile && `border: none;`}
    }
    &[data-radius='true'] {
      border-radius: 5px;
    }
    &[data-small='true'] {
      .tab-box {
        display: flex;
        justify-content: space-between;
        gap: 0;
      }
    }
  `};
`
