import { FC } from 'react'
import styled from 'styled-components'
import { useGqlBestPostListQuery } from 'generated/graphql.community'
import BestStoryList, { TBestStoryItem } from '../../BestStoryList'
import usePostBestTabs from '../hooks/usePostBestTabs'

const CommunityBestPost: FC = () => {
  const { currentTab, PostBestTabs } = usePostBestTabs()

  const { data, loading } = useGqlBestPostListQuery({
    variables: {
      input: { type: currentTab, pagination: { page: 1, pageSize: 10 } },
    },
    context: {
      clientName: 'community',
      headers: {
        'community-type': 'community',
      },
    },
    fetchPolicy: 'no-cache',
  })

  const dataSet: TBestStoryItem[] =
    data?.bestPostList?.bestPosts?.map((post) => ({
      href: post.url || '',
      content: post.title?.replace(/<[^>]*>?/g, '') || '',
      commentCount: post.commentCount,
    })) || []

  return (
    <StyledWrapper>
      <div className="tab-box">
        {PostBestTabs.map((tab) => (
          <button
            key={tab.label}
            className="tab"
            data-active={tab.active}
            onClick={tab.onClick}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <BestStoryList data={dataSet} loading={loading} />
    </StyledWrapper>
  )
}

export default CommunityBestPost

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  .tab-box {
    display: flex;
    gap: 16px;
    .tab {
      cursor: pointer;
      border: none;
      padding: 0px;
      background: none;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      &[data-active='true'] {
        color: #01a0ff;
        font-weight: bold;
      }
      :hover {
        color: #01a0ff;
      }
    }
  }
`
